import "./styles/main.scss";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import MainLayout from "./layout/MainLayout";
import registerServiceWorker from "./registerServiceWorker";
import store, { history } from "./store/createStore";

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="row">
        <MainLayout />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
);

registerServiceWorker();
