export const routes = {
  login: {
    path: "/login",
    name: "Login",
    component: "Login",
    icon: "",
    className: "",
    exact: true,
    show: true
  },
  home: {
    path: "/",
    name: "Home",
    component: "Home",
    icon: "",
    className: "",
    exact: true,
    show: true
  },
  requestAccess: {
    path: "/request-access",
    name: "Request Access",
    component: "RequestAccess",
    icon: "",
    className: "",
    exact: true,
    show: true
  }
};

export const TOGGLE_OPEN_APP = "TOGGLE_OPEN_APP";
