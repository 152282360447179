import styled from "styled-components/macro";

import { APP_BLACK, APP_PRIMARY, APP_WHITE } from "../../conf/colors";

export const Button = styled.button`
  display: block;
  background: ${props => (props.submit ? "transparent" : APP_PRIMARY)};
  color: ${APP_BLACK};
  border: ${props => (props.submit ? `2px solid ${APP_BLACK}` : "none")};
  border-radius: 12.6px;
  margin: 10px auto;
  padding: 10px 50px;
  font-size: 16px;
  ${props => (props.submit ? "max-width: 240px" : "")};
  width: 100%;

  &:active,
  &:focus {
    color: ${props => !props.submit && APP_WHITE};
  }
`;
