import styled from "styled-components/macro";

export const BottomHomeWrapper = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 10vh;
  max-width: 80vw;
  margin: auto;
  left: 0;
  right: 0;
  display: table;
  width: fit-content;
`;

export const EnterButton = styled(BottomHomeWrapper.withComponent("button"))`
  top: calc(50vh - 70px);
  background: none;
  padding: 0;
  border: none;
  bottom: auto;
  &:focus,
  &:active {
    outline: 0;
    border: 0;
  }
`;

export const LogoImg = styled.img`
  max-width: 100%;
  height: 100%;
  max-height: 25vh;
  display: block;
  margin: auto;
`;

export const ButtonLogo = styled(LogoImg)`
  margin: auto;
  width: 140px;
  height: 140px;
`;
