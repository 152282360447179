import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-default.css";

import React from "react";
import Alert from "react-s-alert";
import styled from "styled-components/macro";

import Routes from "../../routes";

const MainContent = styled.main`
  height: 100%;
  min-height: 100vh;
`;

export const MainLayout = () => (
  <MainContent>
    <Routes />

    <Alert stack={{ limit: 3 }} />
  </MainContent>
);

export default MainLayout;
