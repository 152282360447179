import PropTypes from "prop-types";
import React, { memo } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { VelocityComponent, VelocityTransitionGroup } from "velocity-react";

import { TOGGLE_OPEN_APP } from "../conf/enums";
import {
  animation,
  bottomAnimation,
  buttonAnimation,
  enterAnimation,
  leaveAnimation
} from "./amimations";
import Logo from "./assets/stroggulo_shma_3d.png";
import { Button } from "./styledComponents/Button";
import { BottomDiv, TopDiv } from "./styledComponents/Div";
import {
  BottomHomeWrapper,
  ButtonLogo,
  EnterButton
} from "./styledComponents/Logo";
import { Text, TopText, TopTextNoBorder } from "./styledComponents/Text";
import { WhiteLogo } from "./WhiteLogo";

const HOTEL_ROUTE = "/hotel";
const HOME_ROUTE = "/";
const HOME_SUCCESS = "/success";
const DURATION = 1200;

const goToUrl = (goTo, path, external) => () => {
  if (external) {
    const a = Object.assign(document.createElement("a"), {
      target: "_blank",
      href: path,
      rel: "noopener noreferrer"
    });
    document.body.appendChild(a);
    a.click();
    return;
  }
  goTo(path);
};

export const RouteWrapper = ({
  open,
  height,
  toggleOpen,
  children,
  path,
  hotelPage,
  goTo,
  successPage,
  bottleUrl,
  bottleName,
  homePage
}) => [
  <VelocityComponent
    key="topHome"
    animation={animation(open)}
    duration={DURATION}
  >
    <TopDiv>
      {!open &&
        (hotelPage ? (
          <WhiteLogo />
        ) : successPage ? (
          <>
            <TopText>
              All set, check your email! <br />
            </TopText>
            <br />
            <br />
            <br />
            <TopTextNoBorder style={{ textAlign: "center" }}>
              You unlocked a free bottle of: <br /> <br />
              {bottleName}
            </TopTextNoBorder>
          </>
        ) : (
          <TopText>
            We believe that every moment, every sip must be perfect! <br />
            ... must be ready for an extraordinary adventure
          </TopText>
        ))}
    </TopDiv>
  </VelocityComponent>,
  <VelocityTransitionGroup
    key="form"
    enter={enterAnimation}
    leave={leaveAnimation}
  >
    {open && children}
  </VelocityTransitionGroup>,
  <VelocityComponent
    animation={buttonAnimation(open, height)}
    duration={DURATION}
    key="logo"
  >
    <EnterButton
      type="button"
      onClick={() => {
        if (homePage || open) toggleOpen(path);
      }}
    >
      <ButtonLogo src={successPage ? bottleUrl : Logo} alt="logo" />
      <VelocityTransitionGroup leave={leaveAnimation}>
        {!open && homePage && <Text>Press to claim your free bottle</Text>}
      </VelocityTransitionGroup>
    </EnterButton>
  </VelocityComponent>,
  <VelocityComponent
    key="bottomHome"
    animation={bottomAnimation(open)}
    duration={DURATION}
  >
    <BottomDiv>
      {!open && (
        <BottomHomeWrapper>
          {hotelPage ? (
            [
              <Button key="signIn" onClick={goToUrl(goTo, "/hotel/login")}>
                Sign in
              </Button>,
              <Button
                key="access"
                onClick={goToUrl(goTo, "/hotel/request-access")}
              >
                Request access
              </Button>
            ]
          ) : successPage ? (
            [
              <Text key="text">You want to see more cool stuff?</Text>,
              <Button
                key="website"
                onClick={goToUrl(goTo, "https://dorodouli.com", true)}
              >
                Visit our website
              </Button>
            ]
          ) : (
            <WhiteLogo />
          )}
        </BottomHomeWrapper>
      )}
    </BottomDiv>
  </VelocityComponent>,
  <div key="child" style={{ display: "none" }}>
    {children}
  </div>
];

RouteWrapper.propTypes = {
  open: PropTypes.bool,
  successPage: PropTypes.bool,
  bottleUrl: PropTypes.string,
  height: PropTypes.number,
  toggleOpen: PropTypes.func,
  path: PropTypes.string,
  hotelPage: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  const path = state.routing.location.pathname;
  const success = (state.home || {}).success || {};
  const hotelPage = path === HOTEL_ROUTE;
  const successPage = path === HOME_SUCCESS;
  return {
    successPage,
    open: !hotelPage && state.main.openApp,
    height: window.innerHeight / 2 - 70,
    children: ownProps.children,
    path,
    hotelPage,
    bottleUrl: success.image,
    bottleName: success.name,
    homePage: !hotelPage && !successPage
  };
};

const mapDispatchToProps = dispatch => ({
  toggleOpen: (path = "") => {
    path.includes(HOTEL_ROUTE)
      ? dispatch(push(HOTEL_ROUTE))
      : dispatch(push(HOME_ROUTE));
    dispatch({ type: TOGGLE_OPEN_APP });
  },
  goTo: path => {
    dispatch(push(path));
    dispatch({ type: TOGGLE_OPEN_APP });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(RouteWrapper));
