import PropTypes from "prop-types";
import React, { memo, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import RouteWrapper from "../components/RouteWrapper";

const AsyncComponent = Component =>
  memo(props => (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  ));

const Home = React.lazy(() => import(/* webpackPrefetch: true */ "./Home"));
const Login = React.lazy(() => import(/* webpackPrefetch: true */ "./Login"));
const RequestAccess = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./RequestAccess")
);

const RedirectComponent = () => <Redirect to="/" push={true} />;
const Routes = ({ childProps }) => (
  <RouteWrapper>
    <div>
      <Switch>
        <Route
          path="/"
          component={AsyncComponent(Home)}
          exact={true}
          props={childProps}
        />
        <Route
          path="/success"
          component={AsyncComponent(Home)}
          exact
          props={childProps}
        />
        <Route
          path="/hotel"
          component={AsyncComponent(Home)}
          exact
          props={childProps}
        />
        <Route
          path="/hotel/login"
          component={AsyncComponent(Login)}
          exact
          props={childProps}
        />
        <Route
          path="/hotel/request-access"
          component={AsyncComponent(RequestAccess)}
          exact
          props={childProps}
        />
        {/* Finally, catch all unmatched routes */}
        <Route render={RedirectComponent} />
      </Switch>
    </div>
  </RouteWrapper>
);

export default Routes;

Routes.propTypes = {
  childProps: PropTypes.shape({})
};
