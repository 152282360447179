import { TOGGLE_OPEN_APP } from "../../conf/enums";
const SET_APP_STATUS = "SET_APP_STATUS";
// --------------------------------------------------
// Actions
// --------------------------------------------------

export const toggleOpenApp = () => dispatch =>
  dispatch({ type: TOGGLE_OPEN_APP });

export const setAppStatus = payload => dispatch =>
  dispatch({ type: SET_APP_STATUS, payload });

// --------------------------------------------------
// Map actions and their payload to type consts
// --------------------------------------------------

// const updateLimit = limit => ({});

export const actions = {
  toggleOpenApp
};

// --------------------------------------------------
// Action Handlers
// --------------------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_OPEN_APP]: state => ({
    ...state,
    openApp: !state.openApp
  }),
  [SET_APP_STATUS]: (state, action) => ({
    ...state,
    openApp: action.payload
  })
};

// --------------------------------------------------
// Reducer
// --------------------------------------------------
const initialState = {
  openApp: false
};

export default function mainReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
