import styled from "styled-components/macro";

export const Text = styled.p`
  color: white;
  font-size: 1rem;
  margin: auto;
`;
export const TopTextNoBorder = styled(Text)`
  position: relative;
  z-index: 1;
  top: 5vh;
  max-width: 80vw;
  display: flex;
  justify-content: center;
`;
export const TopText = styled(TopTextNoBorder)`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;
