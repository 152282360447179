export const enterAnimation = {
  animation: { opacity: 1 },
  delay: 450,
  style: { opacity: 0 }
};

export const leaveAnimation = {
  animation: { opacity: 0 },
  style: { opacity: 1 }
};

export const animation = openApp => ({
  rotateZ: openApp ? "15deg" : 0,
  top: openApp ? "-48vh" : 0,
  right: openApp ? "-20vw" : "50vw",
  width: openApp ? "100vw" : "200vw"
});

export const buttonAnimation = (openApp, height) => ({
  scale: openApp ? 0.6 : 1,
  top: openApp ? "-25px" : height,
  left: openApp ? "36vw" : 0,
  right: openApp ? "-36vw" : 0
});

export const bottomAnimation = openApp => ({
  rotateZ: openApp ? "15deg" : 0,
  top: openApp ? "48vh" : 0,
  right: openApp ? "20vw" : "50vw",
  width: openApp ? "100vw" : "200vw"
});
