import styled from "styled-components/macro";

import { APP_BLACK, APP_PRIMARY } from "../../conf/colors";

export const Div = styled.div`
  margin: auto;
  width: 80vw;
  max-width: 500px;
  display: block;
  font-size: 14px;
`;

export const TopDiv = styled.div`
  background: ${APP_PRIMARY};
  position: relative;
  top: 0;
  width: 100vw;
  height: 50vh;
`;

export const BottomDiv = styled(TopDiv)`
  background: ${APP_BLACK};
  bottom: 0;
`;
